<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Notas</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <NotasTables :lstNotas="lstNotas" />
      </div>
      <div v-if="btn" class="col-12"><NotasForm /></div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, provide, reactive } from "vue-demi";
import NotasTables from "../components/NotasTables.vue";
import NotasForm from "../components/NotasForm.vue";
import { useStore } from "vuex";
import useNotas from "../composable/useNotas"
export default {
  components: {
    NotasForm,
    NotasTables,
  },
  setup() {
    const btn = inject("btn");
    const store = useStore();
    const {getNotas,lstNotas} = useNotas();
    const objNotas = reactive({
      id: null,
      modulo: 1,
      moneda:null,
      contenido: "",
      status: true,
      deleted: false,
    });

    onMounted(async()=>{
      store.commit("showLoader")
      await getNotas();
      store.commit("hideLoader")
    })

    provide("objNotas", objNotas);
  

    return { btn, lstNotas };
  },
};
</script>

<style>
</style>