<template>
  <button type="button" class="btnCerrarVentana" @click="cerrar"><i class="fas fa-times"></i></button>
</template>

<script>
import { inject } from "vue-demi";
export default {
  setup() {
    const btn = inject("btn");

    const cerrar = () => {
      btn.value = false;
    };

    return { cerrar };
  },
};
</script>

<style>




.btnCerrarVentana {
 box-shadow: inset 0px 1px 0px 0px #fff;
  /* background: linear-gradient(to bottom, #fe1a00 5%, #ce0100 100%); */
  background-color: #fff;
  /* border-radius: 6px; */
  border: 1px solid #fff;
  display: inline-block;
  cursor: pointer;
  color: #000;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 5px 4px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #fff;
  width: 30px;
  height: 30px;
}
.btnCerrarVentana:hover {
  /* background: linear-gradient(to bottom, #c62d1f 5%, #f24537 100%); */
  background-color: #c7c7c7;
}
.btnCerrarVentana:active {
  position: relative;
  top: 1px;
}

</style>