<template>
  <div class="text-end mt-2">
    <button @click="agregarNota" class="myButtonAceptar">Agregar</button>
  </div>

  <div class="card mt-2 table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Modulo</th>
          <th>Moneda</th>
          <th>Status</th>
          <th>Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="m of lstNotas" v-bind:key="m.id">
        <tr style="cursor: pointer">
          <td @click="select(m)">{{ getModulo(m.modulo) }}</td>
          <td @click="select(m)">{{m.moneda}}</td>
          <td @click="select(m)">{{ getStatus(m.status) }}</td>
          <td>
            <button class="btnEliminarTable" @click="eliminarNota(m)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import useNotas from "../composable/useNotas";
export default {
  props: ["lstNotas"],
  components: {
    // Paginador,
  },
  setup() {
    const btn = inject("btn");
    const objNotas = inject("objNotas");
    const store = useStore();
    const {getNotas , deleteNotas} = useNotas();
    const agregarNota = () => {
      objNotas.id = null;
      objNotas.modulo = 1;
      objNotas.moneda = null;
      objNotas.contenido = "";
      objNotas.status = true;
      objNotas.deleted = false;
      btn.value = true;
    };

    const select = (e) => {
      objNotas.id = e.id;
      objNotas.modulo = e.modulo;
      objNotas.moneda = e.moneda;
      objNotas.contenido = e.contenido;
      objNotas.status = e.status;
      objNotas.deleted = e.deleted;
      btn.value = true;
    };

    const eliminarNota = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objNotas.id = e.id;
          store.commit("showLoader")
          await deleteNotas(objNotas)
          await getNotas();       
          store.commit("hideLoader")
          btn.value = false;
        }
      });
    };

    const getModulo = (value) => {
      if (value == 1) {
        return "Recarga";
      }
      if (value == 2) {
        return "Retiro";
      }
      if (value == 3) {
        return "Recarga(Movil)";
      }
    };
    const getStatus = (value) => {
      if (value) {
        return "Activo";
      } else {
        return "Inactivo";
      }
    };

    return {
      agregarNota,
      select,
      eliminarNota,
      getModulo,
      getStatus
    };
  },
};
</script>

<style>
</style>