<template>
  <textarea name="editor1" id="editor1"></textarea>
</template>
<script>
import { ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core';

export default {
    name:'CKEditor',
    props:['content'],
    setup(props,{emit}){
        const content = ref(props.content);
        let ckEditor;
        onMounted(()=>{
            ckEditor = window.CKEDITOR.replace("editor1",{
                width: '100%',
                height: '25em',               
            });
            ckEditor.setData(content.value);  
            
            ckEditor.on('change',()=>{
                emit('sendContent',ckEditor.getData());
            });
        })
        watch(content, ()=>{
            if(content.value !== ckEditor.getData()){
                ckEditor.setData(content.value);  
            }
        });
    }
}
</script>

<style>


</style>