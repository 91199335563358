<template>
  <form @submit.prevent="submitNotas" class="notasForm">
    <div class="text-end text-light">
      <Cerrar />
    </div>

    <div class="row">
      <h4 v-if="objNotas.id" class="text-light text-center">
        Editando Registro
      </h4>
      <h4 v-else class="text-light text-center">Nuevo Registro</h4>
    </div>

    <div class="row mt-3">
      <div class="col-md-3">
        <label class="text-light">Modulo</label>
        <select name="" id="" class="form-select" v-model="objNotas.modulo">
          <option value="1">Recarga</option>
          <option value="2">Retiro</option>
          <option value="3">Recarga(Movil)</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="text-light">Moneda</label>
        <select name="" id="" class="form-select" v-model="objNotas.moneda">
          <option value="USD">USD</option>
          <option value="VES">VES</option>
          <option value="COP">COP</option>
          <option value="PEN">PEN</option>
          <option value="CLP">CLP</option>
        </select>
      </div>
      <div class="col-md-1">
        <!-- <label class="text-light">Ganadores</label> -->
        <div class="form-check">
          <input
            class="form-check-input text-center"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            v-model="objNotas.status"
          />
          <label class="form-check-label text-light" for="flexCheckDefault">
            Status
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 py-3">
        <CKEditor @sendContent="getContent" :content="objNotas.contenido" />
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount, ref } from "vue-demi";
import { useStore } from "vuex";
import CKEditor from "@/components/CKEditor.vue";
import Cerrar from "@/components/button/Cerrar.vue";
import useNotas from "../composable/useNotas";
import Swal from "sweetalert2";
export default {
  components: {
    CKEditor,
    Cerrar,
  },
  setup() {
    const store = useStore();
    const editor = ref();
    const objNotas = inject("objNotas");
    const btn = inject("btn");
    const { createNotas, editNotas, getNotas } = useNotas();
    const getContent = (val) => {
      objNotas.contenido = val;
    };

    const submitNotas = async () => {
      let resp = "";
      store.commit("showLoader");
      if (objNotas.id) {
        resp = await editNotas(objNotas);
      } else {
        resp = await createNotas(objNotas);
      }
      await getNotas();
      store.commit("hideLoader");

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
      cerrar();
    };

    const cerrar = () => (btn.value = false);

    onBeforeUnmount(() => {
      objNotas.id = null;
      objNotas.modulo = null;
      objNotas.contenido = "";
      objNotas.status = true;
      objNotas.deleted = false;
      btn.value = false;
    });

    return {
      editor,
      objNotas,
      submitNotas,
      cerrar,
      getContent,
    };
  },
};
</script>

<style scoped>
.notasForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
}
</style>